var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { id: "CasinoGroup Component", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-dialog",
            {
              staticClass: "col-6 ml-auto mr-auto",
              attrs: { width: "40%" },
              model: {
                value: _vm.createDialog,
                callback: function ($$v) {
                  _vm.createDialog = $$v
                },
                expression: "createDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { class: "headline primary py-3 mb-3" }, [
                    _vm._v(" Create Casino Category "),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.validEdit,
                            callback: function ($$v) {
                              _vm.validEdit = $$v
                            },
                            expression: "validEdit",
                          },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              autofocus: "",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.minMaxLength(1, 40),
                                _vm.noSameName,
                              ],
                              label: "Name",
                            },
                            model: {
                              value: _vm.textField,
                              callback: function ($$v) {
                                _vm.textField = $$v
                              },
                              expression: "textField",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.createDialog = false
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: !_vm.validEdit,
                            color: "primary",
                            text: "",
                          },
                          on: { click: _vm.unholyCrap },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { staticClass: "v-card--material pa-3" },
                [
                  _c("CardHeading", {
                    attrs: {
                      loading: _vm.loading,
                      loadingTitle: `Loading categories...`,
                      color: "secondary",
                      title: "Categories",
                    },
                  }),
                  _vm.list.length > 0 || _vm.loading
                    ? _c(
                        "v-row",
                        { staticClass: "mb-2 align-center" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 pl-2",
                              attrs: { cols: "9", md: "4" },
                            },
                            [
                              _c("v-autocomplete", {
                                staticClass: "mx-2 mr-md-3",
                                attrs: {
                                  loading: _vm.loading,
                                  disabled: _vm.loading,
                                  label: "Groups",
                                  "item-text": "name",
                                  items: _vm.sortedList,
                                },
                                on: { change: _vm.rowClickHandle },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function ({ item }) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(item.name)),
                                          ]),
                                          _c("v-spacer"),
                                          item?.removed
                                            ? _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    color: "error",
                                                    small: "",
                                                  },
                                                },
                                                [_vm._v(" Removed ")]
                                              )
                                            : _c(
                                                "v-chip",
                                                { attrs: { small: "" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.casinoCategoryMap.get(
                                                          item.name
                                                        )?.length ?? 0
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3599339166
                                ),
                                model: {
                                  value: _vm.selectedItem,
                                  callback: function ($$v) {
                                    _vm.selectedItem = $$v
                                  },
                                  expression: "selectedItem",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 pt-0 pb-2 pb-md-4",
                              attrs: { cols: "3", md: "4" },
                            },
                            [
                              _vm.list.length > 0
                                ? _c("ConfirmDialog2", {
                                    attrs: {
                                      persistent: "",
                                      confirmBtnText: "Delete",
                                      confirmBtnColor: "error",
                                      cancelBtnColor: "kajot-text",
                                      cancelBtnText: "Back",
                                      shouldShow: true,
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.changeRemoval(true)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "body",
                                          fn: function () {
                                            return [
                                              _vm._v(
                                                " This category will be deleted. "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " Do you wish to continue ? "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "default",
                                          fn: function ({ showConfirm }) {
                                            return [
                                              _vm._usrFlagsSome({
                                                key: "meta_casinos.detail",
                                                val: _vm.permissions.DELETE,
                                              })
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "error mr-1 mr-md-3",
                                                      attrs: {
                                                        fab: "",
                                                        loading: _vm.loading,
                                                        small: !_vm.isMobile,
                                                        "x-small": _vm.isMobile,
                                                      },
                                                      on: {
                                                        click: () => {
                                                          if (
                                                            _vm.list.find(
                                                              (el) =>
                                                                el.name ===
                                                                _vm.selectedItem
                                                            )?.removed
                                                          ) {
                                                            _vm.changeRemoval(
                                                              false
                                                            )
                                                          } else {
                                                            showConfirm()
                                                          }
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.list.find(
                                                                (el) =>
                                                                  el.name ===
                                                                  _vm.selectedItem
                                                              )?.removed
                                                                ? "mdi-restore"
                                                                : "mdi-delete"
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      113942401
                                    ),
                                  })
                                : _vm._e(),
                              _vm._usrFlagsSome({
                                key: "meta_casinos.detail",
                                val: _vm.permissions.CREATE,
                              })
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary",
                                      attrs: {
                                        fab: "",
                                        small: !_vm.isMobile,
                                        "x-small": _vm.isMobile,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.createDialog = true
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._usrFlagsSome({
                            key: "meta_casinos.detail",
                            val: _vm.permissions.EDIT,
                          })
                            ? _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("EditComponent", {
                                    ref: "editComponent",
                                    attrs: {
                                      fullArray: _vm.casinoList.data,
                                      selectedArray: _vm.categoryCasinos,
                                      loading: _vm.loading,
                                      noDialog: "",
                                      noModel: "",
                                      maxChips: 3,
                                      valueKey: "casino_id",
                                      labelKey: "website",
                                      width: 200,
                                      title: "Edit Casino List",
                                      placeholderText: "Casinos",
                                      color: "primary",
                                    },
                                    on: {
                                      submitEditComponent: _vm.editCategory,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "appendItem",
                                          fn: function ({ item }) {
                                            return [
                                              _c("v-chip", [
                                                _vm._v(
                                                  "ID: " +
                                                    _vm._s(item.casino_id)
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3846141862
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.list.length > 0 || _vm.loading
                    ? _c("v-divider", { staticClass: "mb-3" })
                    : _vm._e(),
                  _vm.loading
                    ? _c("v-skeleton-loader", { attrs: { type: "image@2" } })
                    : _c(
                        "div",
                        { staticClass: "d-flex justify-center" },
                        [
                          _vm.list.length === 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-center align-center",
                                  staticStyle: {
                                    opacity: "0.5",
                                    height: "490px",
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { size: "30" } }, [
                                    _vm._v("mdi-database"),
                                  ]),
                                  _c("span", { staticClass: "mb-3" }, [
                                    _vm._v("No categories found"),
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.createDialog = true
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { staticClass: "mr-2" }, [
                                        _vm._v("mdi-plus"),
                                      ]),
                                      _vm._v(" Create "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-row",
                                { staticClass: "d-flex", attrs: { dense: "" } },
                                _vm._l(
                                  _vm.categoryCasinos,
                                  function (item, index) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: item.website,
                                        staticClass: "pb-2",
                                        attrs: {
                                          cols: "12",
                                          md: "6",
                                          lg: "4",
                                          xl: "3",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass: "elevation-1 ma-0",
                                            class: {
                                              "mb-4":
                                                index <
                                                _vm.categoryCasinos.length - 1,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticClass:
                                                  "secondary d-flex align-center justify-space-between py-2 body-1",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.website) +
                                                    " "
                                                ),
                                                _c("v-chip", [
                                                  _vm._v(
                                                    "ID: " +
                                                      _vm._s(item.casino_id)
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-text",
                                              { staticClass: "py-1" },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    "Country: " +
                                                      _vm._s(item.country)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                        ],
                        1
                      ),
                  _vm._usrFlagsSome({
                    key: "meta_casinos.detail",
                    val: _vm.permissions.EDIT,
                  }) && _vm.list.length > 0
                    ? _c(
                        "v-card-actions",
                        { staticClass: "stickyButtons" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { disabled: _vm.disableSubmit, text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.editComponent.reset()
                                },
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "primary--text mr-1",
                                  attrs: { fab: "", small: "" },
                                },
                                [_vm._v(" mdi-restore ")]
                              ),
                              _vm._v(" Reset "),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { disabled: _vm.disableSubmit, text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.editComponent.submit()
                                },
                              },
                            },
                            [_vm._v(" Submit ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }