<template>
	<v-container
		id="CasinoGroup Component"
		fluid
		tag="section">
		<v-row dense>
			<v-dialog
				width="40%"
				class="col-6 ml-auto mr-auto"
				v-model="createDialog">
				<v-card>
					<v-card-title :class="'headline primary py-3 mb-3'">
						Create Casino Category
					</v-card-title>
					<v-card-text>
						<v-form
							ref="form"
							v-model="validEdit">
							<v-text-field
								autofocus
								:rules="[rules.required, rules.minMaxLength(1, 40), noSameName]"
								v-model="textField"
								label="Name"></v-text-field>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn
							@click="createDialog = false"
							text>
							Cancel
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn
							@click="unholyCrap"
							:disabled="!validEdit"
							color="primary"
							text>
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-col>
				<v-card class="v-card--material pa-3">
					<CardHeading
						:loading="loading"
						:loadingTitle="`Loading categories...`"
						:color="'secondary'"
						title="Categories"></CardHeading>
					<v-row
						v-if="list.length > 0 || loading"
						class="mb-2 align-center">
						<v-col
							cols="9"
							md="4"
							class="pa-0 pl-2">
							<v-autocomplete
								class="mx-2 mr-md-3"
								:loading="loading"
								:disabled="loading"
								label="Groups"
								v-model="selectedItem"
								item-text="name"
								@change="rowClickHandle"
								:items="sortedList">
								<template #item="{ item }">
									<span>{{ item.name }}</span>
									<v-spacer></v-spacer>
									<v-chip
										color="error"
										v-if="item?.removed"
										small>
										Removed
									</v-chip>
									<v-chip
										v-else
										small>
										{{ casinoCategoryMap.get(item.name)?.length ?? 0 }}
									</v-chip>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col
							cols="3"
							md="4"
							class="px-0 pt-0 pb-2 pb-md-4">
							<ConfirmDialog2
								v-if="list.length > 0"
								persistent
								:confirmBtnText="'Delete'"
								:confirmBtnColor="'error'"
								:cancelBtnColor="'kajot-text'"
								:cancelBtnText="'Back'"
								:shouldShow="true"
								@confirm="changeRemoval(true)">
								<template v-slot:body>
									This category will be deleted.
									<br />
									Do you wish to continue ?
								</template>
								<template v-slot:default="{ showConfirm }">
									<v-btn
										fab
										v-if="
											_usrFlagsSome({
												key: 'meta_casinos.detail',
												val: permissions.DELETE,
											})
										"
										:loading="loading"
										:small="!isMobile"
										:x-small="isMobile"
										class="error mr-1 mr-md-3"
										@click="
											() => {
												if (
													list.find((el) => el.name === selectedItem)?.removed
												) {
													changeRemoval(false);
												} else {
													showConfirm();
												}
											}
										">
										<v-icon>
											{{
												list.find((el) => el.name === selectedItem)?.removed
													? "mdi-restore"
													: "mdi-delete"
											}}
										</v-icon>
									</v-btn>
								</template>
							</ConfirmDialog2>
							<v-btn
								fab
								v-if="
									_usrFlagsSome({
										key: 'meta_casinos.detail',
										val: permissions.CREATE,
									})
								"
								:small="!isMobile"
								:x-small="isMobile"
								class="primary"
								@click="createDialog = true">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-col>

						<v-col
							v-if="
								_usrFlagsSome({
									key: 'meta_casinos.detail',
									val: permissions.EDIT,
								})
							"
							cols="12"
							md="4">
							<EditComponent
								:fullArray="casinoList.data"
								:selectedArray="categoryCasinos"
								:loading="loading"
								noDialog
								noModel
								:maxChips="3"
								ref="editComponent"
								valueKey="casino_id"
								labelKey="website"
								:width="200"
								title="Edit Casino List"
								placeholderText="Casinos"
								color="primary"
								v-on:submitEditComponent="editCategory">
								<template #appendItem="{ item }">
									<v-chip>ID: {{ item.casino_id }}</v-chip>
								</template>
							</EditComponent>
						</v-col>
					</v-row>
					<v-divider
						v-if="list.length > 0 || loading"
						class="mb-3"></v-divider>
					<v-skeleton-loader
						v-if="loading"
						type="image@2"></v-skeleton-loader>
					<div
						v-else
						class="d-flex justify-center">
						<div
							style="opacity: 0.5; height: 490px"
							class="d-flex flex-column justify-center align-center"
							v-if="list.length === 0">
							<v-icon size="30">mdi-database</v-icon>
							<span class="mb-3">No categories found</span>
							<v-btn
								@click="createDialog = true"
								small>
								<v-icon class="mr-2">mdi-plus</v-icon>
								Create
							</v-btn>
						</div>
						<v-row
							v-else
							dense
							class="d-flex">
							<v-col
								class="pb-2"
								cols="12"
								:key="item.website"
								v-for="(item, index) in categoryCasinos"
								md="6"
								lg="4"
								xl="3">
								<v-card
									class="elevation-1 ma-0"
									:class="{ 'mb-4': index < categoryCasinos.length - 1 }">
									<v-card-title
										class="secondary d-flex align-center justify-space-between py-2 body-1">
										{{ item.website }}
										<v-chip>ID: {{ item.casino_id }}</v-chip>
									</v-card-title>
									<v-card-text class="py-1">
										<div>Country: {{ item.country }}</div>
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</div>
					<v-card-actions
						v-if="
							_usrFlagsSome({
								key: 'meta_casinos.detail',
								val: permissions.EDIT,
							}) && list.length > 0
						"
						class="stickyButtons">
						<v-spacer></v-spacer>

						<v-btn
							@click="$refs.editComponent.reset()"
							:disabled="disableSubmit"
							text>
							<v-icon
								fab
								small
								class="primary--text mr-1">
								mdi-restore
							</v-icon>
							Reset
						</v-btn>
						<v-btn
							@click="$refs.editComponent.submit()"
							:disabled="disableSubmit"
							text>
							Submit
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import permissions from "../../mixins/permissions";
import EditComponent from "../../components/jackpots/EditComponent.vue";
import CardHeading from "../../components/shared/CardHeading.vue";
import { rules as defaultRules } from "../../plugins/rules.ts";
import ConfirmDialog2 from "../../components/shared/ConfirmDialog2.vue";
import { isMobile } from "../../constants/helpers";
import _ from "lodash";

export default {
	components: {
		CardHeading,
		EditComponent,
		ConfirmDialog2,
	},
	mixins: [permissions],
	data() {
		return {
			validEdit: false,
			loading: true,
			rules: { ...defaultRules },
			noSameName: (value) => {
				return (
					!this.list
						.map((el) => el.name.toLowerCase())
						.includes(value.toLowerCase()) || "Category already exists."
				);
			},
			textField: "",
			newCasinos: [],
			createDialog: false,
			selectedItem: "",
			modifyGroup: false,
			permGroupHeaders: [{ text: "Name", value: "name", align: "center" }],
		};
	},
	computed: {
		isMobile,
		...mapGetters(["casinoList"]),
		...mapGetters("categories", {
			list: "list",
			detail: "detail",
		}),
		...mapGetters({
			casinoCategoryMap: "casinoCategoryMap",
		}),
		disableSubmit() {
			return (
				this.loading ||
				_.isEqual(
					_.sortBy(this.$refs?.editComponent?._selected),
					_.sortBy(this?.detail.map((el) => el.casino_id))
				)
			);
		},
		sortedList() {
			return [...this.list]
				.filter((el) => {
					if (
						!this._usrFlagsSome({
							key: "meta_casinos.detail",
							val: this.permissions.DELETE,
						})
					) {
						return !el.removed;
					} else {
						return el;
					}
				})
				.sort((a, b) => {
					if (a.removed) {
						return 1;
					}
					if (b.removed) {
						return -1;
					}
					return (
						(this.casinoCategoryMap.get(b.name)?.length ?? 0) -
						(this.casinoCategoryMap.get(a.name)?.length ?? 0)
					);
				});
		},
		categoryCasinos() {
			return _.sortBy([...this.detail], "casino_id");
		},
	},
	methods: {
		...mapActions(["getCasinoList"]),
		...mapActions("categories", {
			loadList: "loadList",
			loadDetail: "loadDetail",
			changeCasinos: "changeCasinos",
			create: "create",
			change: "change",
		}),
		async editCategory(e) {
			this.loading = true;
			const added = _.difference(
				e,
				this.detail.map((el) => el.casino_id)
			);
			const removed = _.difference(
				this.detail.map((el) => el.casino_id),
				e
			);
			await this.changeCasinos({
				category: this.selectedItem,
				payload: { added: { casinos: added }, removed: { casinos: removed } },
			});
			await this.loadList();
			await this.getCasinoList();
			await this.loadDetail(this.selectedItem);
			this.loading = false;
		},
		async unholyCrap() {
			this.loading = true;
			await this.create(this.textField),
				await Promise.all([this.loadList(), this.getCasinoList()]);
			this.createDialog = false;
			this.loading = false;
		},
		async changeRemoval(removed) {
			this.loading = true;
			await this.change({
				category: this.selectedItem,
				removed,
			});
			await this.loadList();
			this.loading = false;
		},
		async rowClickHandle(e) {
			if (!e) return;
			this.loading = true;
			await this.loadDetail(e.toLowerCase());
			this.selectedItem = e.toLowerCase();
			this.loading = false;
		},
	},
	async created() {
		await this.loadList();
		this.loading = true;
		if (this.list.length <= 0) {
			this.loading = false;
			return;
		}
		const preselect = this.$route.query.selected;
		let preselectIndex = this.sortedList.findIndex(
			(el) => el.name === preselect
		);
		if (preselectIndex === -1) preselectIndex = 0;
		this.loading = true;
		await this.loadDetail(this.sortedList[preselectIndex].name);
		this.selectedItem = await this.sortedList[
			preselectIndex
		].name.toLowerCase();

		this.loading = false;
	},
};
</script>

<style scoped>
::v-deep tr.v-data-table__selected {
	background-color: var(--v-primary-base) !important;
}

.stickyButtons {
	background-color: var(--v-defaultbg-base);
	position: sticky;
	z-index: 1;
	bottom: 16px;
}

.WIP {
	color: black;
	background: repeating-linear-gradient(
		45deg,
		var(--v-warning-base),
		var(--v-warning-base) 10px,
		#000000 10px,
		#000000 20px
	);
	background-size: 400% 400%;
	padding: 5px 0 !important;
	overflow: hidden;
	contain: content;
}

.VERYWIP {
	animation: gradient 150s linear infinite forwards;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
		filter: brightness(1);
	}
	10% {
		filter: brightness(1.5);
	}
	20% {
		filter: brightness(1);
	}
	30% {
		filter: brightness(1.5);
	}
	40% {
		filter: brightness(1);
	}
	50% {
		filter: brightness(1.5);
	}
	60% {
		filter: brightness(1);
	}
	70% {
		filter: brightness(1.5);
	}
	80% {
		filter: brightness(1);
	}
	90% {
		filter: brightness(1.5);
	}
	100% {
		background-position: 100% 50%;
		filter: brightness(1);
	}
}
</style>
